import React from "react";
import { Link } from "gatsby";
import { TOptions } from "i18next";

import i18n from "../i18n/i18n";
import Layout from "../components/Layout";
import PageStripe from "../components/PageStripe";
import SEO from "../components/SEO";

const Services = ({ pageContext }) => {
  const t = (key: string, options?: TOptions) => {
    return i18n.t(key, { ...options, lng: pageContext.lang });
  };
  const services: {
    name: string;
    description: string;
    action: string;
    link: string;
  }[] = t("servicePage.SERVICES.services", { returnObjects: true }) as any;
  const sectionWidth = "w-4/5 md:w-8/12 lg:w-7/12 2xl:w-5/12 m-auto";

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={t("servicePage.SEO.title")}
        description={t("servicePage.SEO.description")}
        pageContext={pageContext}
      ></SEO>
      <PageStripe
        name={t("servicePage.title")}
        bgImage="services.jpg"
      ></PageStripe>
      <section className={`${sectionWidth}`}>
        {services.map((service, key) => {
          return (
            <div key={key} className="mt-10">
              <h3
                className={`uppercase inline-block border-b-4 border-gray text-3xl`}
              >
                {service.name}
              </h3>
              <p className="mt-5 text-justify">{service.description}</p>
              <Link className="underline mt-5 block hover:text-blue-600" to={service.link}>
                {service.action}
              </Link>
            </div>
          );
        })}
      </section>
    </Layout>
  );
};

export default Services;
